import React from "react"
import Layout from "../../components/Layout"
import AppScreenshot from "../../images/screenshots/Patient_innen_Aufgaben.png"
import MobilePresentationImage from "../../components/MobilePresentationImage"
import SplitView from "../../components/SplitView"
import { Button } from "../../components/text/Button"
import { H1 } from "../../components/text/H1"
import { Subheadline } from "../../components/text/Subheadline"
import Section from "../../components/Section"
import CTAElement from "../../components/CTAElement"
import TaskOverviewScreenshot from "../../images/screenshots/task_overview_patient.png"
import SingleTaskScreenshot from "../../images/screenshots/single_task_patient.png"
import SingleTaskMobileScreenshot from "../../images/screenshots/Einfache_Aufgabe_Patient_in.png"

export interface FunktionenTherapeutInnenProps {}

const FunktionenTherapeutInnen: React.SFC<FunktionenTherapeutInnenProps> = () => {
  return (
    <Layout>
      <SplitView
        elementLeft={
          <>
            <H1 text="Unterstützung bei Ihren therapeutischen Hausaufgaben" />
            <Subheadline text="Die Psychotherapie App unterstützt Sie dabei, Ihre von Psychotherapeut*innen gestellten therapeutischen Hausaufgaben leichter zu meistern. Ihre Hausaufgaben befinden sich nun an einem zentralen Platz. Sie können Ihre therapeutischen Hausaufgaben von überall aus erledigen. Egal, ob Sie gerade in der Bahn, in einem Café oder im Park sind." />
            <div>
              <Button
                text="Mehr erfahren"
                to="/funktionen/patient_innen/#zeit"
                className="text-center"
              />
            </div>
          </>
        }
        elementRight={
          <MobilePresentationImage src={AppScreenshot} alt="Screenshot App" />
        }
        scrollId="zeit"
      />

      <Section background id="zeit">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <img
                  className="mb-6 w-full rounded-lg"
                  src={TaskOverviewScreenshot}
                  alt=""
                />
              </div>
            </div>
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md ml-auto">
                <span className="text-green-600 font-bold">
                  von überall und zu jeder Zeit
                </span>
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Leichter Zeit finden
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Übungsmaterialien immer dabei
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Machen Sie Ihre Hausaufgaben, wann immer Sie Zeit und
                        Lust haben.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">Übersicht</h3>
                      <p className="text-gray-500 leading-loose">
                        Sehen Sie auf einen Blick, welche Aufgaben noch zu
                        erledigen sind und was Sie schon alles geschafft haben.
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Erinnerungen (in Kürze verfügbar)
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Lassen Sie sich zu festgelegten Zeiten erinnern, dass
                        Sie an Ihren Hausaufgaben arbeiten möchten.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md mr-auto">
                {/* <span className="text-green-600 font-bold">
                  Dolor sit amet consectutar
                </span> */}
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Aufbereitete Hausaufgaben
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">Vielfalt</h3>
                      <p className="text-gray-500 leading-loose">
                        Profitieren Sie davon, dass Therapeut*innen digitale
                        Formate wie Bilder, Videos oder Audiodateien
                        bereitstellen können.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Ergänzende Informationen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Psychotherapeut*innen können Ihnen ergänzende
                        Informationen bereitstellen, damit Sie den Ablauf Ihrer
                        Übung immer genau kennen und wissen warum Sie daran
                        arbeiten sollten.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <MobilePresentationImage
                  src={SingleTaskMobileScreenshot}
                  alt="Screenshot App"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section background>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <img
                  className="mb-6 w-full rounded-lg"
                  src={SingleTaskScreenshot}
                  alt=""
                />
              </div>
            </div>
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md ml-auto">
                {/* <span className="text-green-600 font-bold">
                  immer wissen, was man machen soll
                </span> */}
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Passende Eingabefelder
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">Texteditor</h3>
                      <p className="text-gray-500 leading-loose">
                        Bei größeren Texteingaben können Sie mit Hilfe eines
                        Texteditors Ihre Texte durch Formatierungen hervorheben.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">Auswahllisten</h3>
                      <p className="text-gray-500 leading-loose">
                        Sie wissen genau, welche Eingaben Sie machen sollen, da
                        Therapeut*innen Ihnen passende Auswahlmöglichkeiten
                        bereitstellen.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <CTAElement patient />
    </Layout>
  )
}

export default FunktionenTherapeutInnen
